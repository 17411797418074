import styled, { css } from 'styled-components';

import { fontWeights, spacing } from '@hultafors/eripiowear/helpers';

export type H3BottomSpacing = 'small' | 'tiny' | 'tinier';

interface H3StyledProps {
  $uppercase?: boolean;
  $center?: boolean;
  $bottomSpacing?: H3BottomSpacing;
}

export const H3Styled = styled.h3<H3StyledProps>`
  font-size: 22px;
  line-height: 28px;
  font-weight: ${fontWeights.normal};
  letter-spacing: 2px;
  text-transform: ${({ $uppercase }) => ($uppercase ? 'uppercase' : 'none')};
  text-align: ${({ $center }) => ($center ? 'center' : 'inherit')};
  margin-block-start: 0;
  text-wrap: balance;
  ${({ $bottomSpacing }) =>
    $bottomSpacing === 'small'
    && css`
      margin-block-end: ${spacing.tiny};
    `}
`;
