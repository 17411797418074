import dynamic from 'next/dynamic';

import { colors } from '@hultafors/eripiowear/helpers';
import { HeroFragment, ImageBasicFragment } from '@hultafors/eripiowear/types';

import {
  BigDescription,
  BigTitle,
  ContentBoxBig,
  ContentBoxSmall,
  Description,
  DescriptionWrapper,
  HeroBlockStyled,
  HeroOuter,
  HeroSubtitle,
  Inner,
  PreTitle,
  Title,
} from './hero.styled';

const HeroImage = dynamic(() =>
  import('../hero-image/hero-image').then((module) => module.HeroImage),
);

const InfoBox = dynamic(() =>
  import('../info-box/info-box').then((module) => module.InfoBox),
);

const LinkButton = dynamic(() =>
  import('../link-button/link-button').then((module) => module.LinkButton),
);

const SearchBox = dynamic(() =>
  import('../search-box/search-box').then((module) => module.SearchBox),
);

export interface HeroProps extends Omit<HeroFragment, '__typename' | 'id'> {
  iconImage?: ImageBasicFragment;
  iconInformationTitle?: string;
  priority?: boolean;
  noGradient?: boolean;
  wide?: boolean;
  searchBox?: boolean;
}

export const Hero: React.FC<HeroProps> = ({
  alignContentLeft,
  boxColor,
  ctaPageLink,
  ctaLink,
  ctaLabel,
  description,
  heroContentBoxBig,
  heroContentBoxSmall,
  iconImage,
  iconInformationTitle,
  image,
  thinImage,
  mobileImage,
  preTitle,
  searchBox,
  subtitle,
  tabletImage,
  title,
  priority,
  noGradient,
  wide,
  thin,
}) => {
  const ctaHref = ctaPageLink?.slug || ctaLink;
  return (
    <HeroOuter $marginBottom={!heroContentBoxSmall && !heroContentBoxBig}>
      <HeroBlockStyled
        $image={!!image || !!thinImage}
        $noGradient={noGradient}
        $thin={!thinImage || thin}
      >
        <HeroImage
          mobile={mobileImage || undefined}
          tablet={tabletImage || undefined}
          desktop={(thin ? thinImage : image) || undefined}
          priority={priority}
        />
        <Inner $left={alignContentLeft}>
          {preTitle && <PreTitle>{preTitle}</PreTitle>}
          {!heroContentBoxBig && title && (
            <Title $shadow={!!image} $withIcon={alignContentLeft} $wide={wide}>
              {title}
            </Title>
          )}
          {subtitle && <HeroSubtitle $image={!!image}>{subtitle}</HeroSubtitle>}
          {description && !heroContentBoxSmall && !heroContentBoxBig && (
            <DescriptionWrapper $withLine={alignContentLeft}>
              <Description $shadow={!!image} $withLine={alignContentLeft}>
                {description}
              </Description>
            </DescriptionWrapper>
          )}
          {ctaLabel && ctaHref && (
            <LinkButton large={true} text={ctaLabel} url={ctaHref} />
          )}
          {searchBox && <SearchBox transparent wide />}
        </Inner>
      </HeroBlockStyled>
      {heroContentBoxSmall && (
        <ContentBoxSmall $boxColor={boxColor?.hex || colors.gray4}>
          {description}
        </ContentBoxSmall>
      )}
      {heroContentBoxBig && (
        <ContentBoxBig>
          <BigTitle>{title}</BigTitle>
          {subtitle && <HeroSubtitle $image={!!image}>{subtitle}</HeroSubtitle>}
          {iconImage?.url && (
            <div>
              <InfoBox title={iconInformationTitle} icon={iconImage.url} />
            </div>
          )}
          <BigDescription>{description}</BigDescription>
        </ContentBoxBig>
      )}
    </HeroOuter>
  );
};
