import { H3BottomSpacing, H3Styled } from './h3.styled';

export interface H3Props {
  children?: React.ReactNode;
  className?: string;
  uppercase?: boolean;
  center?: boolean;
  bottomSpacing?: H3BottomSpacing;
}

export const H3: React.FC<H3Props> = ({
  center,
  uppercase,
  bottomSpacing,
  children,
  className,
}) => (
  <H3Styled
    $center={center}
    $uppercase={uppercase}
    $bottomSpacing={bottomSpacing}
    className={className}
  >
    {children}
  </H3Styled>
);
